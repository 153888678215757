import React from "react";
import "./App.css";
import SummaryCard from "./Components/SummaryCard/SummaryCard";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Root(props) {
  return (
    <>
      <div className="App">
        <div
          className="container-main"
        >
          <div style={{ justifyContent: "end", display: "flex" }}>
          </div>
          <SummaryCard />
        </div>
      </div>
    </>
  );
}
