import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./summaryCard.css";
import Card from "../Cards/Cards";
import CardController from "./SummaryCardController";

const SummaryCard = () => {
  const [cardsInfo, setCardsInfo] = useState([]);
  const [cardInfoOriginal, setCardsInfoOriginal] = useState([]);
  const [show, setShow] = useState(true);
  const [filtered, setFiltered] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    async function fetchData() {
      const responseCardData = await CardController.fetchCardData();
      const cardInfoArray = responseCardData.shippingCompanies;
      setCardsInfoOriginal(cardInfoArray);
      setCardsInfo(cardInfoArray);
      setShow(false);
    }

    fetchData();

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function filteredCards() {
    try {
      var responseCardData = null;
      if (filtered == false) {
        setCardsInfo(
          cardsInfo.filter(
            (company) =>
              company.inventory === true && company.ssl.scacCode != null
          )
        );
        setFiltered(true);
      } else {
        setCardsInfo(cardInfoOriginal);
        setFiltered(false);
      }
    } catch (error) {
      console.error("Error al filtrar los datos:", error);
    }
  }

  return (
    <div className="container-fluid" id="SummarySSL">
      <div className="row" style={{ justifyContent: "center" }}>
        <h1 className="text-center ssl-title">
          <div class="row" style={{ display: "flex", alignItems: "center" }}>
            <div className="col-sm col-image-yms-container">
              <section
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.top.location.href =
                    process.env.REACT_APP_GO_TO_DRAYPOWER_URL;
                }}
              >
                <img
                  style={{ maxHeight: "50px", height: "50px" }}
                  src="https://staccportalimagesprd.blob.core.windows.net/yms-images/app-bar-images/CircleYms.png"
                  alt="UPS"
                />
                <h4 style={{ margin: "0 0 0 10px" }}>Go to Draypower</h4>
              </section>
            </div>
            <div className="col-sm">SSL Summary</div>{" "}
            <div className="col-sm col-switch-yms-container">
              <input
                className="react-switch-checkbox"
                id="react-switch-new"
                type="checkbox"
                onClick={filteredCards}
              />
              <label
                style={{ background: filtered && "#06D6A0" }}
                className="react-switch-label"
                htmlFor="react-switch-new"
              >
                <span className="react-switch-button" />
              </label>
              <p style={{ fontSize: "40%", margin: "0 0 0 8px" }}>
                Hide No Inventory
              </p>
            </div>
          </div>
        </h1>
      </div>

      <div
        className="row row-ssl"
        style={{
          justifyContent: "center",
          boxShadow:
            "0 8px 11px -5px rgba(0,0,0,.2),0 17px 26px 2px rgba(0,0,0,.14),0 6px 32px 5px rgba(0,0,0,.12)",
          overflowY: "scroll",
          maxHeight: "100%",
          minHeight: "100%",
        }}
      >
        {show ? (
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: "70vh" }}
          >
            <div
              className="spinner-border"
              role="status"
              style={{ alignSelf: "center", scale: "5", color: "#22224f" }}
            ></div>
          </div>
        ) : (
          cardsInfo.map((cardInfo, index) => (
            <div
              key={index}
              className="col-md-4 col-lg-3 col-xl-2 col-sm-6 col-xs-12"
              style={{ padding: "2%" }}
            >
              <Card cardInfo={cardInfo} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SummaryCard;
