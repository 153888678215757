import {
  getCardsLines,
  getNullCards,
  getCurrentLines,
} from "../../services/services";
import ShippingCompany from "../../models/CardModel";

class CardController {
  static async fetchCardData() {
      const response = await getCardsLines();
      const currentLines = await getCurrentLines();
      
      const shippingCompanies = convertToShippingCompanies(
        response.data.data,
        currentLines.data
      );
      return { shippingCompanies };
  }

  static async filterOnlyInvetoryCardData() {
    try {
      const response = await getCardsLines();
      const currentLines = await getCurrentLines();
      
      const shippingCompanies = convertToShippingCompanies(
        response.data.data,
        currentLines.data
      ).filter(company => company.inventory === true);
      return { shippingCompanies };
    } catch (error) {
      console.error("Error al cargar los datos:", error);
      throw error;
    }
  }

  static handleClickCard(cardInfo) {}

  // static async fetchNull() {
  //   try {
  //     const response = await getNullCards();
  //     const shippingCompanies = convertToShippingCompanies(response.data);
  //     return { shippingCompanies };
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
}

export default CardController;

const containerSizes = ["20 ST", "40 ST", "40 HC", "45 HC"];
const convertToShippingCompanies = (mappedData, currentLines) => {
  const shippingCompanies = [];
  currentLines.forEach((line) => {
    // Buscar el scacCode correspondiente en mappedData
    let matchingLine = mappedData.find(
      (mappedLine) => mappedLine.ssl.scacCode === line.ssl.scacCode
    );
    // Si se encuentra una coincidencia, copiar containerFound a currentLines
    if (matchingLine) {
      line.containerFound = matchingLine.containerFound;
    }
    
    const { ssl, containerFound } = line;
    const containers = containerSizes.map((size) => {
      const matchingContainer = containerFound.find(
        (container) => container.size === size
      ) || { size: size, quantity: 0 };
      return {
        Size: matchingContainer.size,
        Quantity: matchingContainer.quantity,
      };
    });
    const hasInventory = containers.some((container) => container.Quantity > 0);
    shippingCompanies.push(new ShippingCompany(ssl, containers, hasInventory));
  }
  );


  mappedData.forEach((item)=>{
    if(item.ssl.scacCode == null){
      const { ssl, containerFound } = item;
          var quantityNulls = 0;
        containerFound.forEach((cot) => {quantityNulls += cot.quantity})
      const hasInventory = (quantityNulls > 0);
      var containersNulls = [{
        Size: "UNK",
        Quantity: quantityNulls,
      },
      {
        Size: "",
        Quantity: "",
      },
      {
        Size: "",
        Quantity: "",
      },
      {
        Size: "",
        Quantity: "",
      }]
      var sslNulls = {scacCode : null, name: "UNK"};
      shippingCompanies.push(new ShippingCompany(sslNulls, containersNulls, hasInventory))
    }
  });

  return shippingCompanies;
};
